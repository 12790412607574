import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLogo from "../images/logo.png";
import { useAuth } from "../lib/auth";

function Header() {
	const [top, setTop] = useState(true);

	const { user, signOut } = useAuth();

	const history = useHistory();

	async function logout() {
		await signOut();

		history.push("/");
	}

	// detect whether user has scrolled the page down by 10px
	useEffect(() => {
		const scrollHandler = () => {
			window.pageYOffset > 10 ? setTop(false) : setTop(true);
		};
		window.addEventListener("scroll", scrollHandler);
		return () => window.removeEventListener("scroll", scrollHandler);
	}, [top]);

	const NotLoggedInNav = () => {
		return (
			<nav className="flex flex-grow">
				<ul className="flex flex-grow justify-end flex-wrap items-center">
					<li>
						<Link
							to="/signin"
							className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
						>
							Sign in
						</Link>
					</li>
					<li>
						<Link
							to="/signup"
							className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"
						>
							<span>Sign up</span>
							<svg
								className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
								viewBox="0 0 12 12"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
									fillRule="nonzero"
								/>
							</svg>
						</Link>
					</li>
				</ul>
			</nav>
		);
	};

	const loggedInNav = () => {
		return (
			<nav className="flex flex-grow">
				<ul className="flex flex-grow justify-end flex-wrap items-center">
					<li>
						<Link
							to="#"
							className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
						>
							<img
								className="relative rounded-full mx-2"
								src={require("../images/default_pfp.svg")}
								width="32"
								height="32"
								alt="pfp"
							/>

							<span>{user.email}</span>
						</Link>
					</li>
					<li>
						<button
							onClick={() => logout()}
							className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"
						>
							<span> Logout</span>
							<svg
								className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
								viewBox="0 0 12 12"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
									fillRule="nonzero"
								/>
							</svg>
						</button>
					</li>
				</ul>
			</nav>
		);
	};

	return (
		<header
			className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
				!top && "bg-white blur shadow-lg"
			}`}
		>
			<div className="max-w-6xl mx-auto px-5 sm:px-6">
				<div className="flex items-center justify-between h-16 md:h-20">
					{/* Site branding */}
					<div className="flex-shrink-0 mr-4">
						{/* Logo */}

						<Link to="/" className="block" aria-label="Cruip">
							<img
								src={ReactLogo}
								alt="React Logo"
								width={48}
								height={48}
							/>
						</Link>
					</div>

					{/* Site navigation */}

					{user ? loggedInNav() : NotLoggedInNav()}
				</div>
			</div>
		</header>
	);
}

export default Header;
