import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import Proof from "./pages/Proof";
import Dashboard from "./pages/Dashboard";

import { AuthProvider } from "./lib/auth";
import ConfirmEmail from "./pages/ConfirmEmail";

function App() {
	const location = useLocation();

	useEffect(() => {
		AOS.init({
			once: true,
			disable: "phone",
			duration: 700,
			easing: "ease-out-cubic",
		});
	});

	useEffect(() => {
		document.querySelector("html").style.scrollBehavior = "auto";
		window.scroll({ top: 0 });
		document.querySelector("html").style.scrollBehavior = "";
		focusHandling("outline");
	}, [location.pathname]); // triggered on route change

	return (
		<AuthProvider>
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>
				<Route path="/signin">
					<SignIn />
				</Route>
				<Route path="/signup">
					<SignUp />
				</Route>
				<Route path="/reset-password">
					<ResetPassword />
				</Route>
				<Route path="/proof">
					<Proof />
				</Route>
				<Route path="/dashboard">
					<Dashboard />
				</Route>
				<Route path="/confirmEmail">
					<ConfirmEmail />
				</Route>
			</Switch>
		</AuthProvider>
	);
}

export default App;
