import React, { useContext, useState, useEffect } from "react";
import { supabase } from "./supabase";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [user, setUser] = useState();

	const [loading, setLoading] = useState(true);
	const [authError, setAuthError] = useState(null);

	useEffect(() => {
		const session = supabase.auth.getSession();

		setUser(session?.user ?? null);
		setLoading(false);

		const { data: listener } = supabase.auth.onAuthStateChange(
			(event, session) => {
				setUser(session?.user ?? null);
				setLoading(false);
			}
		);

		return () => {
			return listener?.subscription.unsubscribe();
		};
	}, []);

	const signIn = async ({ email, password }) => {
		const { data, error } = await supabase.auth.signInWithPassword({
			email,
			password,
		});
		if (error) setAuthError(error);
		
	};

	const signUp = async ({ email, password }) => {
		const { data, error } = await supabase.auth.signUp({
			email,
			password,
			options: {
				emailRedirectTo: 'https://coldmessageai.vercel.app/dashboard/'
			  }
		});
		if (error) setAuthError(error);
		
	};

	const signOut = async () => {
		await supabase.auth.signOut();
	};

	const googleSignIn = async () => {
		const { data, error } = await supabase.auth.signInWithOAuth({
			provider: "google",
			options: {
				redirectTo: "https://coldmessageai.vercel.app/dashboard/",
			  }
		});
		if (error) setAuthError(error);
		
	};

	const value = {
		signIn,
		signUp,
		signOut,
		googleSignIn,
		user,
		authError,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
