import React from "react";

import Header from "../partials/Header";

import Footer from "../partials/Footer";

function ConfirmEmail() {
	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Header />
			<section className="relative">
				<div className="max-w-6xl mx-auto px-4 sm:px-6">
					{/* Hero content */}
					<div className="pt-32 pb-12 md:pt-40 md:pb-20">
						{/* Section header */}
						<div className="text-center pb-12 md:pb-16">
							<h1
								className="text-xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
								data-aos="zoom-y-out"
							>
								{" "}
								Check your email and confirm to continue ☺️
							</h1>
						</div>
					</div>
				</div>
			</section>

			{/*  Site footer */}
			<Footer />
		</div>
	);
}

export default ConfirmEmail;
