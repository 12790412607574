import React from "react";

function Proof() {
	return (
		<h1>
			{" "}
			Hello r/Saas, it's actually me. u/middlenameishardwork and Jan 2024
		</h1>
	);
}

export default Proof;
