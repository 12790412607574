import React from "react";

import Header from "../partials/Header";

import Footer from "../partials/Footer";

function Dashboard() {
	return (
		<div className="flex flex-col min-h-screen overflow-hidden">
			{/*  Site header */}
			<Header />
			<main className="flex-grow">
				<section className="relative">
					<div className="max-w-6xl mx-auto px-4 sm:px-6">
						{/* Hero content */}
						<div className="pt-32 pb-12 md:pt-40 md:pb-20">
							{/* Section header */}
							<div className="text-center pb-12 md:pb-16">
								<h1
									className="text-xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
									data-aos="zoom-y-out"
								>
									{" "}
									You do not have access to this content.
									Contact site admin.
								</h1>
							</div>
						</div>
					</div>
				</section>
			</main>

			{/*  Site footer */}
			<Footer />
		</div>
	);
}

export default Dashboard;
